<script>
import { reportService } from "../../../helpers/backend/report.service"
/**
 * Transactions component
 */
export default {
  data() {
    return {
      data: [],
      error: null,
      filter_type: "",
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      showDismissibleAlert: false,
      active: true,
      reportDateTo: new Date(new Date().setHours(7, 0, 0, 0)).toISOString(),
      reportDateFrom: new Date(new Date().setHours(7, 0, 0, 0)).toISOString(),
      reportType: "Summary",
      reportTypeOptions: ["Summary", "Machine", "Product", "Payment Method", "Transaction"],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length
    },
    report_data_name() {
      return "Transaction_Report_" + this.reportType + "_" + new Date(this.reportDateFrom).toDateString() + "-" + new Date(this.reportDateTo).toDateString()
    },
    processedData() {
      if (this.reportType == 'Machine') {
        return this.data.reduce((inc, element) => {
          const find = inc.find((item) => item.machine_id == element.machine_id)
          console.log(find)
          if (find) {
            find.content.push({
              label: element.label,
              sales: element.sales,
              count: element.count,
            })
          } else {
            inc.push({
              machine_id: element.machine_id,
              machine_name: element.machine_name,
              machine_serial_number: element.machine_serial_number,
              content: [{
                label: element.label,
                sales: element.sales,
                count: element.count,
              }],
              total: 'empty'
            })
          }
          return inc
        }, [])
      } else if (this.reportType == 'Product') {
        return this.data.reduce((inc, element) => {
          const find = inc.find((item) => item.product_id == element.product_id)
          console.log(find)
          if (find) {
            find.content.push({
              label: element.label,
              sales: element.sales,
              count: element.count,
            })
          } else {
            inc.push({
              product_id: element.product_id,
              product_name: element.product_name,
              product_sku: element.product_sku,
              content: [{
                label: element.label,
                sales: element.sales,
                count: element.count,
              }],
              total: 'empty'
            })
          }
          return inc
        }, [])
      } else if (this.reportType == 'Summary') {
        return this.data.reduce((inc, element) => {
          const find = inc.find((item) => item.date == element.date)
          console.log(find)
          if (find) {
            find.content.push({
              label: element.label,
              sales: element.sales,
              count: element.count,
            })
          } else {
            inc.push({
              date: element.date,
              content: [{
                label: element.label,
                sales: element.sales,
                count: element.count,
              }],
              total: 'empty'
            })
          }
          return inc
        }, [])
      }
      return this.data
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
    this.$emit(
      "onDateChange",
      new Date().toISOString().split("T")[0] + "T00:00:00Z"
    )
  },
  watch: {
    reportDateFrom: function () {
      if (!this.reportDateFrom.includes("T")) {
        this.reportDateFrom += "T00:00:00Z"
      }
      //this.$emit("onDateChange", this.reportDateFrom);
      this.fetchData()
    },
    reportDateTo: function () {
      if (!this.reportDateTo.includes("T")) {
        this.reportDateTo += "T23:59:59Z"
      } else this.fetchData()
    },
    reportType: function () {
      this.fetchData()
    },
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setActive(b) {
      this.active = b
    },
    refreshList() {
      this.data = []
      reportService
        .getReportSummary(
          this.reportDateFrom,
          this.reportDateTo,
          this.reportType
        )
        .then(
          (data) => {
            this.data = data.report
            this.loading = false
          },
          (err) => {
            this.error =
              "Failed to fetch report data. Error : " +
              err +
              "  Dissmiss to refresh"
            this.loading = false
            this.showDismissibleAlert = true
          }
        )
    },
    fetchData() {
      this.error = null
      this.loading = true
      this.showDismissibleAlert = false
      this.refreshList()
    },
    dateDisabledAfter(ymd, date) {
      return date > new Date(this.reportDateTo.split("T")[0])
    },
    dateDisabledBefore(ymd, date) {
      return (
        new Date(date.toISOString().split("T")[0]).getTime() <
        new Date(this.reportDateFrom.split("T")[0]).getTime() - 100000000
      )
    },
    displayPrice(price) {
      if (Number.isInteger(price)) {
        return new Intl.NumberFormat('id-id', {
          style: 'currency',
          currency: 'IDR',
          maximumFractionDigits: 0,
        }).format(price)
      } else {
        return price
      }
    }
  },
};
</script>
<template>
  <div v-if="this.active">
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <div class="card">
      <div class="card-body">
        <!-- <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <b-dropdown-item>Export Report</b-dropdown-item>
        <b-dropdown-item>Profit</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown> -->

        <!-- <h4 class="card-title mb-4">(.*)</h4> -->

        <!-- Search -->
        <!-- <div class="col-sm-12 col-md-4">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div> -->
        <!-- End search -->
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <label for="datepicker-from">From</label>
            <b-form-datepicker
              id="datepicker-from"
              :date-disabled-fn="dateDisabledAfter"
              v-model="reportDateFrom"
            />
          </div>
          <div class="col-sm-12 col-md-4">
            <label for="datepicker-to">To</label>
            <b-form-datepicker
              id="datepicker-to"
              :date-disabled-fn="dateDisabledBefore"
              v-model="reportDateTo"
            />
          </div>
          <div class="col-sm-12 col-md-4">
            <label for="cmb-report-type">Report Type</label>
            <b-form-select
              id="cmb-report-type"
              v-model="reportType"
              :options="reportTypeOptions"
            />
          </div>
        </div>
        <template v-if="data.length > 0">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-4">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              :items="processedData"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(status)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-success': row.item.status == 'settlement',
                    'badge-warning': row.item.status == 'pending',
                    'badge-danger': row.item.status == 'cancel',
                  }"
                >
                  {{ row.item.status }}
                </div>
              </template>

              <template v-slot:cell(count)="row">
                {{ row.value }} Transactions
              </template>
              <template v-slot:cell(sales)="row">
                {{ displayPrice(row.value) }}
              </template>
              <template v-slot:cell(date)="row">
                {{ new Date(row.value).toDateString() }}
              </template>
              <template v-slot:head(content)>
                <div class="row">
                  <div class="col">Payment Method</div>
                  <div class="col">Sales</div>
                  <div class="col">Quantity</div>
                </div>
              </template>
              <template v-slot:cell(content)="row">
                <div
                  class="row row-content"
                  v-for="(item, index) in row.item.content"
                  :key="index"
                >
                  <div class="col">
                    <div
                      class="badge font-size-12"
                      :class="{
                        'badge-success': item.label == 'gopay',
                        'badge-primary': item.label == 'cash',
                      }"
                    >
                      {{ item.label }}
                    </div>
                  </div>
                  <div class="col">{{ displayPrice(item.sales) }}</div>
                  <div class="col">{{ item.count }}</div>
                </div>
              </template>
              <template v-slot:cell(total)="row">
                {{
                  displayPrice(
                    row.item.content.reduce(
                      (inc, element) => (inc += element.sales),
                      0
                    )
                  )
                }}
              </template>


            <template v-slot:cell(gross_amount)="row">
              {{
                "Rp " +
                row.value
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </template>
            <template v-slot:cell(transaction_date)="row">
              {{ new Date(row.value).toDateString() }}
            </template>
            <template v-slot:cell(transaction_time)="item">
              {{ new Date(item.item.transaction_date).toLocaleTimeString() }}
            </template>
              
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="float-left">
                <download-excel
                  type="xls"
                  :name="report_data_name"
                  :data="data"
                >
                  <b-button variant="light" style="padding: 10px; margin: 5px"
                    >Download Data
                    <i class="ri-download-2-line"></i>
                  </b-button>
                </download-excel>
              </div>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.row-content:first-child {
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid whitesmoke;
}
</style>