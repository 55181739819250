import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const reportService = {
    getReportStats,
    getReportChart,
    getReportSummary,
};

function getReportStats(date) {
    const requestOptions = {
        url : '/api/v1/user/report/stats?report_date='+date,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}
function getReportChart(date) {
    const requestOptions = {
        url : '/api/v1/user/report/chart?report_date='+date,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}
function getReportSummary(date_from,date_to,type) {
    const requestOptions = {
        url : '/api/v1/user/report/summary?from_time='+date_from+'&to_time='+date_to+"&report_type="+type,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}
